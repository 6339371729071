.flex-parent-container {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 2;
    flex-grow: 1;
}

.flex-parent-container > .child-element {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 48em) {
    .flex-image-container {
        margin-right: 1.5rem;
    }
}

.icon-button {
    border-radius: 50%;
    margin: 0.5rem;
    position: relative;
    z-index: 2;
    width: 30px;
    height: 30px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.animation-enter {
    animation: fadeIn 0.5s forwards;
}

.animation-exit {
    animation: fadeOut 0.5s forwards;
}

.project-image {
    border-radius: 0;
    flex-grow: 1;
    object-fit: cover;
}

.text-box {
    padding: 5rem;
    max-width: 100%;
    color: white;
}

@media (min-width: 48em) {
    .text-box {
        max-width: 50%;
    }
}

.heading-xl {
    font-size: 1.25rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 48em) {
    .heading-xl {
        -webkit-line-clamp: 4;
    }
}

.details-content a {
    color: white;
}

.details-content a:hover {
    color: #808080;
}



