.heroContainer {
    position: relative;
    min-height: 68.7vh;
    z-index: 1;
}

.heroLayout {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
}

@media (min-width: 48em) {
    .heroLayout {
        flex-direction: row;
    }
}

.iframeBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: -1;
}

.heroFeatureTiles {
    flex: 1;
    min-width: 50%;
}
