.headerContainer {
    position: relative;
}

.iframeBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: -1;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: transparent;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
}

.logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoImage {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
}

.logoText {
    font-size: 21px;
    font-weight: bold;
    font-family: 'Monotype Corsiva', cursive;
}

.buttonHoverStyle {
    line-height: 1.2em;
    background:
            conic-gradient(from -135deg at 100% 50%, #FFFFFF 90deg, transparent 0) 0 0,
            conic-gradient(from -135deg at 1.2em 50%, transparent 90deg, #FFFFFF 0) 100% 0;
    background-size: 0 200%;
    transition: background-size .4s ease-in, color .4s ease-in;
    font-size: 21px;
    font-weight: bold;
    font-family: 'Monotype Corsiva', cursive;
    color: white;
    margin-right: 35px;
}

.buttonHoverStyle:hover {
    background-size: 100% 200%;
    color: #000000;
}
