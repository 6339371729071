.footerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.iframeBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: -1;
}

.footerText {
    font-size: 21px;
    font-weight: bold;
    font-family: 'Monotype Corsiva', cursive;
    color: white;
    text-align: center;
    width: 100%;
    padding: 2.4rem;
}
