.flex-parent-container {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    z-index: 2;
    background-color: transparent;
}

.flex-parent-container > .child-element {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 48em) {
    .flex-image-container {
        margin-right: 1.5rem;
    }
}

.animation-enter {
    animation: fadeIn 0.5s forwards;
}

.animation-exit {
    animation: fadeOut 0.5s forwards;
}

.project-image {
    border-radius: 0;
    width: 500px;
    height: auto;
}

.text-box {
    padding: 0;
    max-width: 50%;
    color: white;
}

@media (min-width: 48em) {
    .text-box {
        max-width: 50%;
    }
}

.heading-xl {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 48em) {
    .heading-xl {
        -webkit-line-clamp: 4;
    }
}


