.flex-parent-container {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    z-index: 2;
    background-color: transparent;
}

.flex-parent-container > .child-element {
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    max-width: 1200px;
    margin: 1rem;
    padding: 1rem;
}

@media (min-width: 48em) {
    .flex-image-container {
        margin-right: 1.5rem;
    }
}

.icon-button {
    border-radius: 50%;
    margin: 0.5rem;
    width: 30px;
    height: 30px;
}

.project-image {
    border-radius: 0;
    width: 500px;
    height: auto;
}

.text-box {
    padding: 0;
    max-width: 50%;
    color: white;
}

.heading-xl {
    font-size: 1.25rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media (min-width: 48em) {
    .flex-parent-container {
        flex-direction: row;
    }

    .flex-image-container {
        margin-right: 1.5rem;
    }

    .text-box {
        max-width: 50%;
    }

    .heading-xl {
        white-space: normal;
    }
}
